<template>
  <v-sheet elevation="3" class="pa-3">
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field label="Поиск" :value="tableParams.title" clearable @input="setField('title', $event)" />
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <v-select
          :value="tableParams.origin"
          :items="countries"
          label="Страна"
          item-value="uid"
          item-text="text"
          :disabled="withoutCountryProxy"
          @input="setField('origin', $event)"
        />
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <stocks-status :status="tableParams.status" empty-option @change="setField('status', $event)" />
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3">
        <v-select
          :items="exchanges"
          label="Биржа"
          item-value="uid"
          item-text="text"
          @input="setField('exchange', $event)"
        />
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3">
        <v-checkbox v-model="withoutCountryProxy" label="Без страны" />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

import StocksStatus from "../status";

export default {
  mixins: [editorFields],
  props: {
    tableParams: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      countries: [
        { uid: "", text: "Все" },
        { uid: "american", text: "США" },
        { uid: "russian", text: "Россия" },
        { uid: "hong-kong", text: "Китай" },
        { uid: "kazakhstan", text: "Казахстан" }
      ],
      exchanges: [
        { uid: [], text: "Любая биржа" },
        { uid: "SPBE", text: "СПБ Биржа" },
        { uid: "MISX", text: "Мосбиржа" }
      ]
    };
  },
  computed: {
    withoutCountryProxy: {
      get() {
        return Boolean(this.tableParams.withoutCountry);
      },
      set(value) {
        if (value) {
          this.setField("origin", "");
        }

        this.setField("withoutCountry", value ? 1 : 0);
      }
    }
  },
  components: {
    StocksStatus
  }
};
</script>
