<template>
  <div>
    <h2 class="mb-4">Акции</h2>

    <stock-filters class="mb-5" :table-params="tableParams" @change-field="changeField" />

    <data-table
      url="stocks"
      item-key="ticker"
      clickable-rows
      :headers="headers"
      :table-params="tableParams"
      @click:row="editStock"
    >
      <template v-slot:[`item.ticker`]="{ item }">
        <div class="d-flex align-center py-2">
          <div>
            <v-img class="rounded-circle" :src="item.logo" width="44" height="44" />
          </div>

          <div class="ml-4">
            {{ item.ticker }}
          </div>
        </div>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
      </template>

      <template v-slot:[`item.status.value`]="{ item }">
        {{ $store.getters["forms/statusTitleByValue"](item.status.value) }}
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAt | date }}
      </template>

      <template v-slot:[`item.prices.current.time`]="{ item }">
        {{ item.prices.current.time | date }}
      </template>
    </data-table>
  </div>
</template>

<script>
import StockFilters from "~/components/stocks/index/filters";

import { editorForm } from "~/mixins/multi-editor/editor-form";

export default {
  mixins: [editorForm],
  data() {
    return {
      headers: [
        { text: "Тикер", value: "ticker", sortable: false },
        { text: "Компания", value: "company" },
        { text: "Акция", value: "name" },
        { text: "Статус", value: "status.value", sortable: false },
        { text: "Дата создания", value: "createdAt" },
        { text: "Дата цены", value: "prices.current.time" }
      ],

      tableParams: {
        title: "",
        origin: "",
        status: "active",
        withoutCountry: 0,
        exchange: []
      }
    };
  },
  methods: {
    editStock(stock) {
      const route = this.$router.resolve({ name: "stocks-ticker", params: { ticker: stock.ticker } });

      window.open(route.href, "_blank");
    },

    changeField(payload) {
      this.commitChange(this.tableParams, payload);
    }
  },

  components: {
    StockFilters
  }
};
</script>
